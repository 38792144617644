import { InventoryItemKeysType, isInventoryItemKey } from '../../../items/keys';

const AMOUNT_SUFFIX = '_amount';

export const toAmountVarName = (itemKey: InventoryItemKeysType) => `${itemKey}${AMOUNT_SUFFIX}`;

export const getItemKeyFromAmountVarName = (varName: string): InventoryItemKeysType | null => {
  if (!varName.endsWith(AMOUNT_SUFFIX)) return null;
  const k: string = varName.substring(0, varName.length - AMOUNT_SUFFIX.length);
  return isInventoryItemKey(k) ? k : null;
};

export const isAmountVarName = (varName: string): boolean => varName.endsWith(AMOUNT_SUFFIX)
  && !!getItemKeyFromAmountVarName(varName);
