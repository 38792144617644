// gtag added in index.html

import { WithStory } from '../story/types';
import { watchVisitedLocation } from '../visitedLocations/client/ink';
import { getUserIdCookie } from '../auth/client/utils';

export const initAnalytics = (s: WithStory): () => void => {
  try {
    gtag('config', 'GA_MEASUREMENT_ID', {
      user_id: getUserIdCookie(),
    });

    gtag('event', 'init', {
      event_category: 'game',
    });

    const destroyLocationWatch = watchVisitedLocation(s, (l: string) => {
      gtag('event', 'visited', {
        event_category: 'location',
        event_label: l,
      });
    });

    return (): void => {
      destroyLocationWatch();
    };
  } catch (e) {
    console.error('error init analytics', e);
    return () => {};
  }
};
