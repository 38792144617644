import { makeAutoObservable } from 'mobx';

class MainGameState {
  loaded = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export const mainGameState = new MainGameState();
