import omit from 'lodash/omit';
import React from 'react';
import { Modal as BootstrapModal, ModalProps } from 'react-bootstrap';
import { CONTAINER_ID } from '../htmlOverlay/client/constants';
import { useWrapperScale } from '../htmlOverlay/client/scale/hooks';
import styled from 'styled-components';

interface CustomProps {
  bodyContent: React.ReactNode;
  bodyStyle?: {[k: string]: string};
}

interface Props extends ModalProps, CustomProps {

}

const modalPropsToOmitTypeCheck: {[k in keyof CustomProps]: any} = {
  bodyContent: true,
  bodyStyle: true,
};

const modalPropsToOmit = Object.keys(modalPropsToOmitTypeCheck);

const dialogClassName = 'fiddlegame-modal-dialog';
const backdropClassName = 'fiddlegame-backdrop';
const contentClassName = 'fiddlegame-modal-content';
const BootstrapModalStyled = styled(BootstrapModal)`
  .${backdropClassName} {
    position: relative;  
  }
  position: absolute;
  padding-left: 0 !important; // bootstrap quirk https://stackoverflow.com/questions/47259203/cant-change-css-modal-bootstrap-modal-fade-in-bootstrap-auto-add-padding-left
  .modal-dialog {
    margin-left: 10%;
    margin-right: 10%;
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
  }
`;


const WithContentTransformedStyle = styled(BootstrapModalStyled).withConfig<{
  scaleX: number;
  scaleY: number;
}>({
  shouldForwardProp: prop => typeof prop !== 'string' || !['scaleX', 'scaleY'].includes(prop) 
})`
  &.modal {
    transition: none !important; // doesnt work well with custom transforms
    .modal-dialog {
      transition: none !important; // doesnt work well with custom transforms
    }
  }
  &.modal.show {
    
    .modal-dialog.${dialogClassName} {
      transform: ${props => `scale(${props.scaleX}, ${props.scaleY})`};
      transform-origin: left top;
      overflow: visible;
    
    }
  }
  .modal-content.${contentClassName} {
    border-radius: 3.25rem !important;
    background-color: rgba(255,255,255,0.5);
  }
  
`;

export const Modal: React.FC<Props> = (props) => {
  const { scaleX, scaleY } = useWrapperScale();
  return <WithContentTransformedStyle {...omit(props, modalPropsToOmit)}
                                      scaleX={scaleX}
                                      scaleY={scaleY}
                                      dialogClassName={dialogClassName}
                                      backdropClassName={backdropClassName}
                                      contentClassName={contentClassName}
                                      container={document.getElementById(CONTAINER_ID)}
                                      scrollable={false}>
      <BootstrapModal.Body style={{
        ...(props.bodyStyle || {}),
      }}>
        {props.bodyContent}
    </BootstrapModal.Body>
  </WithContentTransformedStyle>;
};