import { isGameVarTransient } from './transientVars';
import { LoadArgs, LoadListenerCb } from './types';

const locationVarListener = (setGameVariable: LoadArgs['setGameVariable']): LoadListenerCb => (varName, v, next) => {
  if (varName === 'current_location') {
    setGameVariable(varName, v._componentsString);
  } else next();
};

const transientListener: LoadListenerCb = (varName, v, next) => {
  if (isGameVarTransient(varName)) return;
  next();
};

export const getDefaultGameVariableListeners = (setGameVariable: LoadArgs['setGameVariable']): LoadListenerCb[] => [
  locationVarListener(setGameVariable), transientListener,
];
