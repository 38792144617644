import React, { MouseEvent } from 'react';
import { ImageProps, Image } from 'react-bootstrap';
import { InventoryDimensions } from '../scenes/inventory/coordinates';
import { useWrapperScale } from '../../../htmlOverlay/client/scale/hooks';
import { action } from 'mobx';
import { inventoryTabsClientState } from '../state';
import { INVENTORY_ITEM_KINDS, InventoryItemKind } from '../../items/kinds';
import { inventoryShift } from './inventoryShift';
import styled from 'styled-components';
import {  useBase64png } from '../../../htmlOverlay/client/assets/context';
import { TabAssetName } from '../assetNames';

const assetNames: {[k in InventoryItemKind]: TabAssetName} = {
  item: 'items_bucket',
  rune: 'runes_bucket',
  weapon: 'weapons_bucket',
};

interface InventoryTabButtonProps extends ImageProps {
  kind: InventoryItemKind;
}

const StyledImage = styled(Image)`
  cursor: pointer;
  opacity: 0.85;
  :hover {
    opacity: 1;
  }
`;

const InventoryTabButton: React.FC<InventoryTabButtonProps> = ({ kind, ...props }) => {
  const base64png = useBase64png(assetNames[kind]);
  return <StyledImage {...props} src={base64png} fluid />;
}


export const InventoryTabButtons: React.FC = () => {
  const onClick = action((e: MouseEvent, k: InventoryItemKind) => {
    e.preventDefault();
    inventoryTabsClientState.currentTab = k;
  });
  const scale = useWrapperScale();
  const { scaleX, scaleY } = scale;
  const shift = inventoryShift(scale);
  return <div style={{
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    ...shift,
    transform: `scale(${scaleX}, ${scaleY})`,
    transformOrigin: 'left top',
    height: `${InventoryDimensions.height}px`,
    width: `${InventoryDimensions.width}px`,
    justifyContent: 'space-between',
    paddingTop: `${InventoryDimensions.cellSide / 8}px`,
    paddingBottom: `${InventoryDimensions.cellSide / 8}px`,
  }}>
    {INVENTORY_ITEM_KINDS.map((k: InventoryItemKind) =>
      <InventoryTabButton key={k} kind={k} title={k} onClick={(e: MouseEvent) => onClick(e, k)}/>)}
  </div>
};