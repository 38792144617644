import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { InventoryOverlay } from '../../inventory/client/components';
import { observer } from 'mobx-react-lite';
import { QueryClientProvider } from 'react-query';
import { mainGameState } from '../../client/state';
import { scaleManagerContext } from './scale/context';
import { assetsContext } from './assets/context';
import { makeAssetsProvider } from './assets/provider';
import { BadgesOverlay } from '../../badges/client/components';
import { CONTAINER_ID } from './constants';
import { queryClient } from '../../client/state/query';

const GameHtmlOverlay: React.FC = observer(() => {
  if (!mainGameState.loaded) return null;
  return <Fragment>
    <BadgesOverlay />
    <InventoryOverlay />
  </Fragment>;
});

export const initHtmlOverlay = (sm: Phaser.Scale.ScaleManager,
                                tm: Phaser.Textures.TextureManager): () => void => {
  const htmlOverlayElement = document.getElementById(CONTAINER_ID);
  if (!htmlOverlayElement) throw new Error(`Cannot find container with id ${CONTAINER_ID}`);
  ReactDOM.unmountComponentAtNode(htmlOverlayElement);
  const assetsProvider = makeAssetsProvider(tm);
  ReactDOM.render(<QueryClientProvider client={queryClient}>
    <assetsContext.Provider value={assetsProvider}>
      <scaleManagerContext.Provider value={sm}>
        <GameHtmlOverlay/>
      </scaleManagerContext.Provider>
    </assetsContext.Provider>
  </QueryClientProvider>, htmlOverlayElement);
  return (): void => {
    ReactDOM.unmountComponentAtNode(htmlOverlayElement);
    htmlOverlayElement.innerHTML = '';
  };
};