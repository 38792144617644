import { PointsEvent } from '../types';
import { getSocket } from '../../../socket/client';

let setUp = false;
let listeners: Array<(p: PointsEvent) => void> = [];
const callback = (e: PointsEvent) => {
  listeners.forEach((l) => l(e));
};

const setup = () => {
  getSocket().on('points', callback);
  setUp = true;
};

export const cleanup = () => {
  getSocket().off('points', callback);
  listeners = [];
};

export const subscribe = (cb: (p: PointsEvent) => void) => {
  if (!setUp) setup();
  listeners = [...listeners, cb];

  return () => {
    listeners = listeners.filter((l) => l !== cb);
  };
};
