import { InventoryItemKeysType } from './keys';
import { InventoryItemKind } from './kinds';

interface Stackability {
  stackable: boolean;
}

interface KindNess {
  kind: InventoryItemKind;
}

interface PriceNess {
  price: number | undefined;
}

interface Priceless {
  price: undefined;
}

const priceless: Priceless = {
  price: undefined,
};

interface WithKey {
  key: InventoryItemKeysType;
}

interface WithDescription {
  description: string;
}

interface WithName {
  name: string;
}

export interface InventoryItemProperties extends WithKey, Stackability, KindNess, PriceNess, WithDescription, WithName {

}

export interface Stackable extends Stackability { stackable: true }
export interface NotStackable extends Stackability { stackable: false }

const stackable: Stackable = { stackable: true };
const notStackable: NotStackable = { stackable: false };

export const magicDrum: InventoryItemProperties = {
  key: 'magic_drum',
  kind: 'item',
  description: 'A mysterious drum engraved with runes which possesses magical \
properties when used by a skilled player.',
  name: 'Magic Drum',
  ...priceless,
  ...notStackable,
};
export const magicDrumBroken: InventoryItemProperties = {
  key: 'magic_drum_broken',
  kind: 'item',
  description: 'The shattered remains of a magic drum. \
While badly broken, its runic engravings remain intact. Perhaps it can be repaired.',
  name: 'Broken Magic Drum',
  ...priceless,
  ...notStackable,
};
export const torch: InventoryItemProperties = {
  key: 'torch',
  kind: 'item',
  description: 'A simple torch made of a wooden stave topped by an oiled rag.',
  name: 'Torch',
  price: 1,
  ...stackable,
};
export const healthPotion: InventoryItemProperties = {
  key: 'health_potion',
  kind: 'item',
  name: 'Health Potion',
  description: 'A bubbling red potion which smells terrible but heals almost any wound. \
A literal life-saver for adventurers like you.',
  price: 1,
  ...stackable,
};
export const smokeBomb: InventoryItemProperties = {
  key: 'smoke_bomb',
  kind: 'item',
  description: 'A small bomb which releases thick smoke and hides you from your enemies.',
  name: 'Smoke Bomb',
  price: 4,
  ...stackable,
};
export const dwarvenDagger: InventoryItemProperties = {
  key: 'dwarven_dagger',
  kind: 'weapon',
  description: 'A shining silver dagger crafted by the dwarves of Grimroot Forest. \
It is known to be particularly strong against monsters.',
  name: 'Dwarven Dagger',
  price: 5,
  ...notStackable,
};
export const magicFiddleBow: InventoryItemProperties = {
  key: 'magic_fiddle_bow',
  kind: 'weapon',
  description: 'A bow which enhances your fiddle\'s power to disrupt dark magic. \
You stand a much better chance against enemy spellcasters when using this bow.',
  name: 'Magic Fiddle Bow',
  price: 5,
  ...notStackable,
};
export const lantern: InventoryItemProperties = {
  key: 'lantern',
  kind: 'item',
  description: 'A silvery oil-burning lantern. Unlike a torch, it\'s re-usable, but it also isn\'t quite as bright.',
  name: 'Lantern',
  price: 3,
  ...notStackable,
};
export const runeOfFire: InventoryItemProperties = {
  key: 'rune_of_fire',
  kind: 'rune',
  description: 'A rune with a bright red engraving that \
is hot to the touch. It allows you to produce fire from your hand.',
  name: 'Rune of Fire',
  price: 5,
  ...notStackable,
};
export const runeOfStrength: InventoryItemProperties = {
  key: 'rune_of_strength',
  kind: 'rune',
  description: 'A heavy rune bearing a yellow emblem. \
It allows you to cast a spell which gives you incredible strength for a short time.',
  name: 'Rune of Strength',
  price: 5,
  ...notStackable,
};
export const runeOfPersuasion: InventoryItemProperties = {
  key: 'rune_of_persuasion',
  kind: 'rune',
  description: 'An elegant rune bearing a light blue emblem. \
It allows you to cast a spell which makes an opponent very susceptible to your suggestions.',
  name: 'Rune of Persuasion',
  ...priceless,
  ...notStackable,
};

export const INVENTORY_ITEM_PROPERTIES: {[k in InventoryItemKeysType]: InventoryItemProperties} = {
  magic_drum: magicDrum,
  magic_drum_broken: magicDrumBroken,
  torch,
  health_potion: healthPotion,
  smoke_bomb: smokeBomb,
  dwarven_dagger: dwarvenDagger,
  magic_fiddle_bow: magicFiddleBow,
  lantern,
  rune_of_fire: runeOfFire,
  rune_of_strength: runeOfStrength,
  rune_of_persuasion: runeOfPersuasion,
};
