import { reaction } from 'mobx';
import { InventoryItem, InventoryStory, makeInventoryItem } from './story';
import { INVENTORY_ITEM_PROPERTIES } from '../items/properties';
import { InventoryItemKeysType, isInventoryItemKey } from '../items/keys';
import { addLoadListener } from '../../saveLoad/client';
import { getItemKeyFromAmountVarName } from './story/items/utils';
import { LoadListenerCb } from '../../saveLoad/client/types';
import { itemIsStackable, itemKindIs } from '../items/utils';
import { InventoryItemKind } from '../items/kinds';
import { InventoryScene } from './scenes/inventory';
import { WithStory } from '../../story/types';
import { inventoryTabsClientState } from './state';

type ModGoldT = (modifier: number) => ('error' | (() => 'error' | number));

export class Inventory {
  private inventoryScene: InventoryScene;

  private inventoryStory: InventoryStory;

  constructor(scene: Phaser.Scene & WithStory) {
    this.inventoryScene = new InventoryScene(scene);
    this.inventoryStory = new InventoryStory(scene);
  }

  private loadGameListener: LoadListenerCb = (k: string, v: any, next: () => void): void => {
    const itemKeyFromAmount: InventoryItemKeysType | null = getItemKeyFromAmountVarName(k);
    const itemProps: {k: InventoryItemKeysType, n?: number} | null = itemKeyFromAmount
      ? { k: itemKeyFromAmount, n: v as number } : (isInventoryItemKey(k) && v) ? { k } : null;
    if (!itemProps) { next(); return; }
    const item: InventoryItem | null = InventoryItem.isValid(itemProps.k, itemProps.n)
      ? makeInventoryItem(itemProps.k, itemProps.n) : null;
    if (!item) { next(); return; }
    this.setItemStoryAmount(item.meta.key, item.amount, item.meta.stackable);
    const tab = this.getTab();
    if (tab) this.rerenderTab(tab);
  };

  onItemClick = (k: InventoryItemKeysType) => {
    if (!this.inventoryStory.isInShop()) {
      this.inventoryStory.inventoryGoto(k);
    }
  };

  init = (): () => void => {
    const destroyInventoryScene = this.inventoryScene.init(this.onItemClick);
    const destroyTabChangeListener = this.listenTabChange();
    const destroyLoadListener = addLoadListener(this.loadGameListener);
    return (): void => {
      [destroyInventoryScene, destroyTabChangeListener, destroyLoadListener].forEach((d) => d());
    };
  };

  getTab = (): InventoryItemKind | null => inventoryTabsClientState.currentTab;

  listenTabChange = (): (() => void) => reaction(() => inventoryTabsClientState.currentTab, (t) => {
    if (t) this.rerenderTab(t);
  }, { fireImmediately: true });

  rerenderTab = (t: InventoryItemKind): void => {
    this.inventoryScene.grid.rerenderWithItems(this.inventoryStory.getItemsOfKind(t));
  };

  private modInventoryF = (current: number, modifier: number): number => current + modifier;

  private removeFromStory = (itemKey: InventoryItemKeysType): void => {
    this.setItemStoryAmount(itemKey, 0, INVENTORY_ITEM_PROPERTIES[itemKey].stackable);
  };

  remove = (itemKey: InventoryItemKeysType): void => {
    this.removeFromStory(itemKey);
    this.inventoryScene.grid.removeItem(itemKey);
  };

  private setItemStoryAmount = (itemKey: InventoryItemKeysType, amount: number | undefined, stackable: boolean)
  : void => {
    if (stackable) this.inventoryStory.setAmount(itemKey, amount || 0);
    this.inventoryStory.tapItem(itemKey, !!amount);
  };

  mod = (itemKey: InventoryItemKeysType, modifier: number):
  'error' | null => {
    if (modifier === 0) return null; // empty operation

    const shouldModifyScene = itemKindIs(itemKey, this.getTab());
    const stackable = itemIsStackable(itemKey);
    const itemAmount = stackable ? this.inventoryStory.getAmount(itemKey)
      : this.inventoryStory.hasItem(itemKey) ? 1 : 0;
    const newAmount = this.modInventoryF(itemAmount, modifier);

    if (newAmount === 0) {
      this.remove(itemKey);
      return null;
    }

    if (itemAmount === newAmount) {
      // not implemented
      if (!stackable) {
        console.error('trying to add already existing non-stackable item');
        return 'error';
      }
      return null;
    }

    if (!stackable && newAmount > 1) {
      console.error(`New amount is ${newAmount} but the item ${itemKey} is not stackable`);
      return 'error';
    }

    if (newAmount < 0) {
      console.error('negative item amount');
      return 'error';
    }

    if (shouldModifyScene) this.inventoryScene.grid.mod(itemKey, itemAmount, newAmount, stackable);
    this.setItemStoryAmount(itemKey, newAmount, stackable);
    this.inventoryScene.inventoryAcquisitionSound(itemKey, modifier);

    return null;
  };

  modGold = (modifier: number): 'success' | 'error' => {
    const modGoldF = this.modGoldT(modifier);
    if (modGoldF === 'error') return modGoldF;
    return modGoldF() === 'error' ? 'error' : 'success';
  };

  checkCoins = (modifier: number): number | 'error' => {
    const coins = this.inventoryStory.getCoins();
    const newCoins = coins + modifier;
    if (newCoins < 0) return 'error';
    return newCoins;
  };

  // "transaction" with validation first and mod command second
  modGoldT: ModGoldT = (modifier: number) => {
    const newCoins = this.checkCoins(modifier);
    if (newCoins === 'error') return 'error';
    return () => { // in case it's done in callback check again
      const newCoinsRechecked = this.checkCoins(modifier);
      if (newCoinsRechecked === 'error') return newCoinsRechecked;
      this.inventoryStory.setCoins(newCoinsRechecked);
      this.inventoryScene.inventoryAcquisitionSound('gold', modifier);
      return newCoinsRechecked;
    };
  };
}
