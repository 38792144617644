import { makeAutoObservable } from 'mobx';
import { QueryObserver, QueryObserverResult, useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { BadgeId, GetBadgesResult, ToggleBadgeRequest } from '../types';
import { queryClient } from '../../client/state/query';
import { BadgeQueryKeyType } from './types/queries';
import { badgesApiRoute } from '../routes/api';
import { badgeIds } from '../constants';
import { isBadgeId } from '../utils';

interface BadgesState extends Set<BadgeId> {}

class Badges {
  isBadgeListOpen = false;

  private readonly badges: BadgesState = new Set<BadgeId>(); // this probably goes to react-query later

  toggleClientBadge = (id: BadgeId, b: boolean): void => {
    this.badges[b ? 'add' : 'delete'](id);
  };

  isActive = (id: BadgeId): boolean => this.badges.has(id);

  constructor() {
    makeAutoObservable(this);
  }
}

export const badgesClientState = new Badges();

const getBadgesQuery = (): Promise<BadgesState> =>
  axios.get<GetBadgesResult>(`${badgesApiRoute}`).then(({ data }) => new Set<BadgeId>(data));

export type ToggleBadgeMutationVariables = ToggleBadgeRequest;

const toggleBadgeMutation = (r: ToggleBadgeMutationVariables): Promise<BadgesState> =>
  axios.post<ToggleBadgeRequest, AxiosResponse<GetBadgesResult>>(`${badgesApiRoute}`, r)
    .then(({ data }) => new Set<BadgeId>(data));

queryClient.setQueryDefaults(BadgeQueryKeyType.BADGE_LIST, {
  queryFn: getBadgesQuery,
  initialData: new Set<BadgeId>(),
});

queryClient.setMutationDefaults(BadgeQueryKeyType.TOGGLE_BADGE, {
  mutationFn: toggleBadgeMutation,
  onSuccess(data: BadgesState): void {
    queryClient.setQueryData<BadgesState>(BadgeQueryKeyType.BADGE_LIST, data);
  },
});

export const useBadgesQuery = () => useQuery<BadgesState>(BadgeQueryKeyType.BADGE_LIST);

export const prefetchBadgesQuery = (): Promise<void> => queryClient.prefetchQuery(BadgeQueryKeyType.BADGE_LIST);
export const clearBadgesQuery = (): Promise<void> => queryClient.resetQueries({
  exact: true, queryKey: BadgeQueryKeyType.BADGE_LIST,
});
const badgesQueryObserver = new QueryObserver(queryClient, { queryKey: BadgeQueryKeyType.BADGE_LIST });
type SubscribeBadgesQueryType = (cb: (r: BadgesState) => void) => () => void;
export const subscribeBadgesQuery: SubscribeBadgesQueryType = (cb) =>
  badgesQueryObserver.subscribe((result: QueryObserverResult) => {
    cb(result.data as BadgesState);
  });

export const toggleBadge = (badgeId: BadgeId, b: boolean): Promise<BadgesState> => {
  // external ink function, check params!
  if (!isBadgeId(badgeId)) throw new Error(`${badgeId} is not valid badge id, valid values ${badgeIds.join(', ')}`);
  return queryClient.executeMutation<BadgesState, {}, ToggleBadgeMutationVariables>({
    mutationKey: BadgeQueryKeyType.TOGGLE_BADGE,
    variables: {
      badgeId, b,
    },
  });
};
