const horizontalCellCount = 3;
const verticalCellCount = 3;
const cellSide = 192;

export const InventoryDimensions = {
  x: 220,
  y: 960,
  cellSide,
  horizontalCellCount,
  verticalCellCount,
  width: cellSide * horizontalCellCount,
  height: cellSide * verticalCellCount,
};
