import { Scene } from 'phaser';
import axios from 'axios';
import assets from 'url:../../assets/images/assets.png';
import pickupMp3 from 'url:../../assets/sounds/pickup.mp3';
import { action } from 'mobx';
import assetsJSON from '../../assets/assets.json';
import storyFile from '../../ink/fqGame.json';
import { mainGameState } from '../client/state';
import { initBadges } from '../badges/client';
import { getUserIdCookie } from '../auth/client/utils';

const validateToken = (tryN = 0) => {
  const MAX_TRY = 3;
  const TRY_TIMEOUT = 300;
  return axios({
    method: 'post',
    url: '/api/auth/validate',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
  }).then((response) => response.data.validated)
    .catch((error) => {
      if (tryN >= MAX_TRY) {
        console.error('error validating auth token in api', error);
        throw error;
      } else {
        return new Promise((success, fail) => {
          setTimeout(() => {
            validateToken(tryN + 1).then(success).catch(fail);
          }, TRY_TIMEOUT);
        });
      }
    });
};

export default class Boot extends Scene {
  authTokenPromise?: Promise<any>;

  initBadgesPromise?: Promise<void>;

  clearBadges?: () => void;

  fontsReady: any;

  constructor() {
    super({ key: 'boot' });
  }

  init() {
    this.fontsReady = false;
  }

  preload() {
    const userId = getUserIdCookie();
    if (!userId) {
      // TODO prevent forever redirect reliably
      // const redirect = '/';
      // if (window.location.pathname !== redirect) { // no forever redirect
      //   window.location.href = redirect;
      // }
      this.authTokenPromise = Promise.resolve();
    } else {
      let isValidated = false;
      this.authTokenPromise = validateToken().then((res) => {
        isValidated = res;
        if (!isValidated) {
          // document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
          // TODO prevent forever redirect reliably
          // const redirect = '/';
          // if (window.location.pathname !== redirect) { // no forever redirect
          //   window.location.href = redirect;
          // }
        }
        // here we know the token is valid
        // TODO fetch saved data with valid token
      });
    }

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'WebFont'.
    WebFont.load({
      google: {
        families: ['Roboto Mono'],
      },
      active: this.fontsLoaded,
    });

    const bar = this.add
      // .rectangle(2048 / 2, 1536 / 2, 100, 8, 0x9c3333)
      .rectangle(2048 / 2, 1536 / 2, 100, 8, 0x5f9eee)
      .setScale(0, 1);

    this.load.on('progress', (progress: number) => bar.setScale(progress * 10, 2));
    this.load.json('storyFile', storyFile);
    this.load.atlas('assets', assets, assetsJSON);
    const [initBadgesPromise, clearBadges] = initBadges();
    this.initBadgesPromise = initBadgesPromise;
    this.clearBadges = clearBadges;

    this.load.audio('pickup', [pickupMp3]);
  }

  toggleGameLoaded = action((b: boolean): void => {
    mainGameState.loaded = b;
  });

  customCleanup = () => {
    this.toggleGameLoaded(false);
    (this.clearBadges || (() => {}))();
    delete this.clearBadges;
  };

  create() {
    Promise.all([this.authTokenPromise/* , and more probably */]).then(() => {
      this.scene.start('game');
      this.toggleGameLoaded(true);
    }).catch((e) => console.error('Error preloading assets', e));
  }

  fontsLoaded = () => {
    this.fontsReady = true;
  };
}
