import Phaser from 'phaser';
import BootScene from '../scenes/Boot';
import GameScene from '../scenes/Game';
import { GLOBAL_HEIGHT, GLOBAL_WIDTH } from './dimensions';

const CANVAS_ID = 'phaser-canvas';
const canvasElement = document.getElementById(CANVAS_ID) as HTMLCanvasElement;

if (!canvasElement) throw new Error(`Cannot find canvas element with id ${CANVAS_ID}`);

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.CANVAS,
  width: GLOBAL_WIDTH,
  height: GLOBAL_HEIGHT,
  backgroundColor: '#000000',
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.NO_CENTER,
  },
  canvas: canvasElement,

  title: 'FiddleQuest Ink Phaser3',
  url: 'no-link',
  scene: [BootScene, GameScene],
};

export default config;
