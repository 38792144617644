import { WithStory } from '../../../story/types';
import { inventoryItemKeys, InventoryItemKeysType } from '../../items/keys';
import { INVENTORY_ITEM_PROPERTIES, InventoryItemProperties } from '../../items/properties';
import { toAmountVarName } from './items/utils';
import { IN_DESCRIPTION_VAR_NAME, IN_SHOP_VAR_NAME } from './gameVariableNames';
import { InventoryItemKind } from '../../items/kinds';
import { ary1 } from '../../../types/utils';

export class InventoryItem {
  static validate = (k: InventoryItemKeysType, amount?: number): null | string => {
    const meta = INVENTORY_ITEM_PROPERTIES[k];
    return (meta.stackable && !amount) ? 'Stackable item supposed to have amount > 0'
      : (!meta.stackable && amount !== undefined) ? 'Non-Stackable item is not supposed to have amount' : null;
  };

  static isValid = (k: InventoryItemKeysType, amount?: number): boolean => !InventoryItem.validate(k, amount);

  readonly meta: InventoryItemProperties;

  readonly amount?: number;

  constructor(k: InventoryItemKeysType, amount?: number) {
    const error = InventoryItem.validate(k, amount);
    if (error) throw new Error(error);
    this.meta = INVENTORY_ITEM_PROPERTIES[k]; this.amount = amount;
  }
}

export const makeInventoryItem = (k: InventoryItemKeysType, amount?: number): InventoryItem =>
  new InventoryItem(k, amount);

export class InventoryStory {
  story: WithStory;

  constructor(story: WithStory) {
    this.story = story;
  }

  inventoryGoto = (name: string): void => {
    this.story.setGameVariable(IN_DESCRIPTION_VAR_NAME, true);
    this.story.continueStory(`${name.replace(/ /g, '_')}_obj`);
  };

  isInShop = (): boolean => Boolean(this.story.getGameVariable(IN_SHOP_VAR_NAME));

  // getInventoryTab = (): InventoryItemKind => {
  //   const r = this.story.getGameVariable(CURRENT_INVENTORY_TAB_VAR_NAME);
  //   if (!r) throw new Error(`variable ${CURRENT_INVENTORY_TAB_VAR_NAME} is not defined in story`);
  //   if (!INVENTORY_ITEM_KINDS.includes(r)) {
  //     throw new Error(`variable ${CURRENT_INVENTORY_TAB_VAR_NAME}
  //     has unexpected value, not one of ${INVENTORY_ITEM_KINDS.join(', ')}`);
  //   }
  //   return r;
  // };

  // setInventoryTab = (t: InventoryItemKind): void => {
  //   this.story.setGameVariable(CURRENT_INVENTORY_TAB_VAR_NAME, t);
  // };

  // listenTabChange = (cb: (t: InventoryItemKind | null) => void): () => void => {
  //   const destroy = this.story.watchGameVariable(CURRENT_INVENTORY_TAB_VAR_NAME, (o: InkObject) => {
  //     cb((o as Value<InventoryItemKind>).value);
  //   });
  //   return (): void => {
  //     destroy();
  //   };
  // };

  setAmount = (itemKey: InventoryItemKeysType, value: number): void => {
    if (!INVENTORY_ITEM_PROPERTIES[itemKey].stackable) {
      console.error(`trying to assign amount story variable to not stackable item ${itemKey}`);
      return;
    }
    this.story.setGameVariable(toAmountVarName(itemKey), value);
  };

  getAmount = (itemKey: InventoryItemKeysType): number =>
    (INVENTORY_ITEM_PROPERTIES[itemKey].stackable
      ? this.story.getGameVariable(toAmountVarName(itemKey))
      : (() => {
        console.error('getAmount is not supposed to be called on non-stackable item');
        return undefined;
      }));

  hasItem = (itemKey: InventoryItemKeysType): boolean => (INVENTORY_ITEM_PROPERTIES[itemKey].stackable
    ? !!this.getAmount(itemKey) : !!this.story.getGameVariable(itemKey));

  tapItem = (itemKey: InventoryItemKeysType, b = true): void => {
    this.story.setGameVariable(itemKey, b);
  };

  private _getItem = (k: InventoryItemKeysType): InventoryItem => makeInventoryItem(k,
    INVENTORY_ITEM_PROPERTIES[k].stackable ? this.getAmount(k) : undefined);

  private _getItems =
  (preFilter: (k: InventoryItemKeysType) => boolean = () => true,
    postFilter: (i: InventoryItem) => boolean = () => true)
  : InventoryItem[] =>
    inventoryItemKeys
      .filter(preFilter).filter(ary1(this.story.getGameVariable))
      .map<InventoryItem>(this._getItem)
      // .filter((i) => !i.meta.stackable || i.amount)
      .filter(postFilter);

  getAllItems = (): InventoryItem[] => this._getItems();

  getItemsOfKind = (k: InventoryItemKind): InventoryItem[] =>
    this._getItems((key) => INVENTORY_ITEM_PROPERTIES[key].kind === k);

  getCoins = (): number => this.story.getGameVariable('coins');

  setCoins = (amount: number): void => this.story.setGameVariable('coins', amount);
}
