import uniqueId from 'lodash/uniqueId';

const visitedLocations = new Set<string>();

// what does it even.
// eslint-disable-next-line no-spaced-func
const watchers = new Map<string, (l: string) => void>();

export const watchVisitedLocations = (cb: (l: string) => void): () => void => {
  const id = uniqueId('visitedLocationsWatcher');
  watchers.set(id, cb);
  return () => watchers.delete(id);
};

export const isLocationVisited = (l: string): boolean => visitedLocations.has(l);

export const markLocationVisited = (l: string) => {
  visitedLocations.add(l);
  try {
    watchers.forEach((cb) => cb(l));
  } catch (e) {
    console.error('error in a visited location watcher', e);
  }
};

export const getVisitedLocationsA = () => Array.from(visitedLocations);

export const setVisitedLocations = (ls: string[]) => {
  visitedLocations.clear();
  ls.forEach((l) => visitedLocations.add(l));
};
