/*
 .container(394, 0, [box, text])
      .setAlpha(0)
      .setSize(box.width, box.height)
      .setInteractive();
    this.tweens.add({
      targets: container,
      y: 71,
      x: 400,
 */

export const BadgesDimensions = {
  buttonX: 70,
  buttonY: 40,
  buttonWidth: 100,
  buttonHeight: 100,
};
