import { Scene } from 'phaser';
import { reaction } from 'mobx';
import { InventoryGrid } from './grid';
import { InventoryItemKeysType } from '../../../items/keys';
import { ObjectsContainer } from '../../../../phaser/client/objects/objects';
import { inventoryTabsClientState } from '../../state';
import { InventoryDimensions } from './coordinates';

export class InventoryScene {
  readonly grid: InventoryGrid;

  readonly scene: Scene;

  readonly pickup: Phaser.Sound.BaseSound;

  container!: ObjectsContainer<Phaser.GameObjects.Container>;

  constructor(scene: Scene) {
    this.pickup = scene.sound.add('pickup', { volume: 0.5 });
    this.scene = scene;
    this.grid = new InventoryGrid(scene);
  }

  inventoryAcquisitionSound = (k: InventoryItemKeysType | 'gold', amount: number): void => {
    if (amount > 0) this.pickup.play();
  };

  toggle = (to: 'grid' | 'tabs'): void => {
    this.grid.toggleDisplay(to === 'grid');
  };

  init = (onItemClick: (k: InventoryItemKeysType) => void): () => void => {
    this.container = new ObjectsContainer(this.scene, undefined, InventoryDimensions.x, InventoryDimensions.y);
    const destroyGrid = this.grid.init(onItemClick, this.container);
    const destroyClientStateListener = reaction(() => inventoryTabsClientState.currentTab, (t) => {
      this.toggle(!t ? 'tabs' : 'grid');
    }, { fireImmediately: true });
    return (): void => {
      [destroyGrid, destroyClientStateListener].forEach((d) => d());
    };
  };
}
