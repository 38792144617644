import axios from 'axios';

import fromPairs from 'lodash/fromPairs';
import debounce from 'lodash/debounce';
import identity from 'lodash/identity';

import { VariablesState } from 'inkjs/engine/VariablesState';
import { InkObject } from 'inkjs/engine/Object';
import { Value } from 'inkjs/engine/Value';
import { LoadGameResponse, SaveGameRequest } from '../types';
import { PointTypes, toVarName as toPointVarName } from '../../points/game/constants';
import { PointsStateForUser } from '../../points/store/types';
import { isGameVarTransient } from './transientVars';
import { getDefaultGameVariableListeners } from './defaultVarListeners';
import { GetGameVariables, LoadArgs, LoadListenerCb } from './types';
import { initAutoSave } from './autoSave';
import { getVisitedLocationsA, setVisitedLocations } from '../../visitedLocations/client/store';

let loading = false;

export const isLoading = () => loading;

const loadPoints = (setPointVarValue: LoadArgs['setPointVarValue'], points: PointsStateForUser) => {
  Object.keys(points).forEach((k: string) => {
    if (k === 'social'/* PointTypes.SOCIAL TODO remove this and don't send social points here at all */) return;
    setPointVarValue(toPointVarName(k as PointTypes), points[k as PointTypes]);
  });
};

let loadListeners: LoadListenerCb[] = [];

export const removeLoadListener = (cb: LoadListenerCb) => {
  loadListeners = loadListeners.filter((l) => l !== cb);
};

export const addLoadListener = (cb: LoadListenerCb): () => void => {
  if (!loadListeners.find((l) => l === cb)) loadListeners.push(cb);
  return () => {
    removeLoadListener(cb);
  };
};

const addDefaultListeners = (setGameVariable: LoadArgs['setGameVariable']): () => void => {
  const defaultListeners = getDefaultGameVariableListeners(setGameVariable).map(addLoadListener);
  return () => {
    defaultListeners.forEach((destroy) => destroy());
  };
};

const loadListenersReducer = (k: string, v: any) => (acc: () => void, cb: LoadListenerCb) => () => {
  try {
    cb(k, v, acc);
  } catch (e) {
    console.error('error in a load game callback', e);
    acc();
  }
};

const projectApiSaveDataOntoGame = (data: {[k in string]: any}, setGameVariable: LoadArgs['setGameVariable']) =>
  // transients wont be loaded; cancelled in default listeners (but why?) TODO maybe check it here
  Object.keys(data || {}).forEach((k) => {
    if (Object.prototype.hasOwnProperty.call(data, k)) {
      const v = data[k];
      loadListeners.reduce(loadListenersReducer(k, v), () => { setGameVariable(k, v); })();
    }
  });

const handleLoadResponse = (args: LoadArgs) => (response: { data: LoadGameResponse }) => {
  const generalSaveStateData = response.data.generalSaveState?.data;
  const extraSaveData = response.data.generalSaveState?.extra;
  projectApiSaveDataOntoGame(generalSaveStateData, args.setGameVariable);
  loadPoints(args.setPointVarValue, response.data.points);
  setVisitedLocations(extraSaveData?.visitedLocations || []);
  (args.continueStory || identity)(generalSaveStateData?.current_location._componentsString as string || undefined);
};

const load = (args: LoadArgs): Promise<any> => {
  loading = true;
  return axios.get('/api/game/load', {
    headers: { 'Content-Type': 'application/json' },
  }).then(handleLoadResponse(args)).catch(console.error.bind(console)).finally(() => { loading = false; });
};

export const getAllGlobalVariables = (s: VariablesState): Map<string, InkObject> => (s as any)._globalVariables;

// crude
const isInkValue = (o: InkObject): o is Value<any> => typeof (o as Value<any>).value !== 'undefined';
const isInkValueAndWarn = (k: string, o: InkObject): o is Value<any> => {
  const isValue = isInkValue(o);
  if (!isValue) console.error('trying to save non-value ink variable', k);
  return isValue;
};

const prepareToSaveData = (getGameVariables: GetGameVariables): {[k in string]: Value<any>} => fromPairs(
  Array.from(getAllGlobalVariables(getGameVariables()).entries())
    .filter(([k]: [string, InkObject]) => !isGameVarTransient(k))
    .filter(([k, v]: [string, InkObject]) => isInkValueAndWarn(k, v))
    .map(([k, v]: [string, InkObject]) => [k, (v as Value<any>).value]), // no narrowing above :sad:
);

type WithCancelPromiseResult = {
  promise: Promise<any>;
  cancel: () => void;
};

const logNonCancelErrors = (e: any) => {
  if (axios.isCancel(e)) {
    return;
  }
  console.error('error during save game', e);
};

const withCancel = (endpoint: string, data: string): WithCancelPromiseResult => {
  const cancelSource = axios.CancelToken.source();
  return {
    cancel: () => { cancelSource.cancel('Save cancelled (probably by next save operation).'); },
    promise: axios.post(endpoint, data, {
      cancelToken: cancelSource.token,
      headers: { 'Content-Type': 'application/json' },
    }).catch(logNonCancelErrors),
  };
};

const save = (getGameVariables: GetGameVariables): WithCancelPromiseResult => {
  if (loading) {
    return {
      promise: Promise.reject(new Error('Trying to save during game loading')),
      cancel: () => {},
    };
  }
  const saveGameRequest: SaveGameRequest = {
    data: prepareToSaveData(getGameVariables),
    extra: {
      visitedLocations: getVisitedLocationsA(),
    },
  };
  return withCancel('/api/game/save', JSON.stringify(saveGameRequest));
};

const debouncedApiSaveWithCancel = (getGameVariables: GetGameVariables): (() => void) => {
  const HALF_SECOND = 500;
  let currentSavePromise: {promise: Promise<any>; cancel: () => void;} = {
    promise: Promise.resolve(), cancel: () => {},
  };
  const saveDebounced = debounce(() => { currentSavePromise = save(getGameVariables); }, HALF_SECOND);
  return () => { currentSavePromise.cancel(); saveDebounced(); };
};

type SaveLoadApiMain = { save: () => WithCancelPromiseResult; load: () => Promise<any>; };
export type SaveLoadApi = { destroy: () => void; } & SaveLoadApiMain;
export type InitArgs = LoadArgs & {getGameVariables: GetGameVariables };

const initMain = (args: InitArgs): SaveLoadApiMain => ({
  save: () => save(args.getGameVariables),
  load: () => load(args),
});

const withDestroyers = (api: SaveLoadApiMain, args: InitArgs): SaveLoadApi => {
  const destroyers = [
    initAutoSave(args.getGameVariables, debouncedApiSaveWithCancel(args.getGameVariables)),
    addDefaultListeners(args.setGameVariable),
  ];
  return {
    ...api,
    destroy: () => {
      destroyers.forEach((destroy) => destroy());
    },
  };
};

let initialised = false;

export const init = (args: InitArgs): SaveLoadApi => {
  if (initialised) throw new Error('saveLoad already initialised'); // reinit not implemented
  initialised = true;
  const api = withDestroyers(initMain(args), args);
  return { ...api, destroy: () => { api.destroy(); initialised = false; } };
};
