import { transaction } from 'mobx';
import {
  badgesClientState, clearBadgesQuery, prefetchBadgesQuery, subscribeBadgesQuery,
} from './state';
import { badgeIds } from '../constants';

export const initBadges = (): [Promise<void>, () => void] => {
  const unsubscribeQueryCache = subscribeBadgesQuery((badges) => {
    transaction(() => {
      badgeIds.forEach((b0) => badgesClientState.toggleClientBadge(b0, badges.has(b0)));
    });
  });
  const p = prefetchBadgesQuery();
  return [p, () => {
    unsubscribeQueryCache();
    // noinspection JSIgnoredPromiseFromCall
    clearBadgesQuery();
  }];
};
