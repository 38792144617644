import React, { useMemo, MouseEvent } from 'react';
import styled from 'styled-components';
import { useAssetsContext } from '../../../htmlOverlay/client/assets/context';
import { FONT_SIZE_BUTTON } from '../../../scenes/styles';
import { action } from 'mobx';
import { badgesClientState } from '../state';

const StyledDiv = styled.div`
  cursor: pointer;
  opacity: 0.85;
  :hover {
    opacity: 1;
  }
`;

const onClick = action((e: MouseEvent<HTMLDivElement>): void => {
  e.preventDefault();
  badgesClientState.isBadgeListOpen = !badgesClientState.isBadgeListOpen;
});

export const BadgesButton: React.FC = () => {
  const assets = useAssetsContext();
  const base64png = useMemo(() => assets.getBase64png('button'), [assets]);
  const frame = useMemo(() => assets.getFrame('button'), [assets]);
  return <StyledDiv style={{
    backgroundImage: `url(${base64png})`,
    backgroundSize: 'contain',
    width: frame.width,
    height: frame.height,
    color: 'white',
    fontSize: FONT_SIZE_BUTTON,
  }} onClick={onClick}>Badges</StyledDiv>;
};