import { Scene } from 'phaser';
import { Story } from 'inkjs';
import { Story as InkStory } from 'inkjs/engine/Story';

import fromPairs from 'lodash/fromPairs';

import { VariablesState } from 'inkjs/engine/VariablesState';
import { InkObject } from 'inkjs/engine/Object';
import { init as saveLoadInit, SaveLoadApi, InitArgs as SaveLoadInitArgs } from '../saveLoad/client';
import {
  PointVarNames, toVarName as toPointVarName,
} from '../points/game/constants';
import { subscribe as pointsSubscribe } from '../points/services/client';
import { PointsEvent } from '../points/services/types';
import { INVENTORY_ITEM_PROPERTIES } from '../inventory/items/properties';
import { InventoryItemKeysType } from '../inventory/items/keys';
import { itemPrice, itemDescription, itemName } from '../inventory/items/utils';
import { WithStory } from '../story/types';
import { Inventory } from '../inventory/client';
import { getArticle } from '../text/utils/a';
import { IN_SHOP_VAR_NAME } from '../inventory/client/story/gameVariableNames';
import { bindExternalFunction } from '../ink/externalFunctions';
import { initCreditsButton } from '../credits/client/phaser/creditsButton';
import { wordWrapWidth, zoneCoords } from './constants/measurements';
import { FONT_SIZE_BUTTON, labelPointStyle } from './styles';
import { bindExternalBadgesFunctions } from '../badges/client/game/functions';
import { ary1, void1 } from '../types/utils';
import { initVisitedLocationsInkWatcher } from '../visitedLocations/client/ink';
import { isLocationVisited } from '../visitedLocations/client/store';
import { initAnalytics } from '../analytics';

const { SONG_POINTS, SKILL_POINTS } = PointVarNames;

export default class Game extends Scene implements WithStory, SaveLoadInitArgs {
  inventory!: Inventory;

  choicesGroup: any;

  itemImage: any;

  locationImage: any;

  story!: InkStory;

  textCoins: any;

  textCredits?: Phaser.GameObjects.Text;

  destroyTextCredits?: () => void;

  textParagraph: any;

  // textSocialPoints: any;

  textSkillPoints: any;

  textSongPoints: any;

  pointsSubscriptionCleanup: (() => void) | null = null;

  saveGame!: SaveLoadApi['save'];

  loadGame!: SaveLoadApi['load'];

  destroySaveLoad!: SaveLoadApi['destroy'];

  destroyInventory!: () => void;

  destroyVisitedLocationsInkWatcher!: () => void;

  destroyAnalytics!: () => void;

  constructor() {
    super({ key: 'game' });
  }

  init() {
    const storyFile = this.game.cache.json.get('storyFile');
    this.story = new Story(storyFile);
  }

  create() {
    const { save, load, destroy } = saveLoadInit(this);
    this.loadGame = load;
    this.saveGame = save;
    this.destroySaveLoad = destroy;
    this.inventory = new Inventory(this);
    this.createGUI();
    this.destroyInventory = this.inventory.init();
    this.destroyVisitedLocationsInkWatcher = initVisitedLocationsInkWatcher(this);
    this.destroyAnalytics = initAnalytics(this);
    this.createExternals();
    this.loadGame().then(() => {
      this.setUpSubscriptions();
    });
  }

  customCleanup = () => {
    try {
      this.tearDownSubscriptions();
      this.destroySaveLoad();
      this.destroyInventory();
      this.destroyVisitedLocationsInkWatcher();
      this.destroyGUI();
    } catch (e) {
      console.error('error cleaning up Game scene', e);
    }
  };

  setUpSubscriptions = () => {
    const setValue = (e: PointsEvent) => this.setPointVarValue(toPointVarName(e.type), e.value);
    this.pointsSubscriptionCleanup = pointsSubscribe(setValue);
  };

  tearDownSubscriptions = () => {
    if (this.pointsSubscriptionCleanup) {
      this.pointsSubscriptionCleanup();
      this.pointsSubscriptionCleanup = null;
    }
  };

  buy = (itemKey: InventoryItemKeysType, count: number): 'success' | 'error' => {
    const cost = INVENTORY_ITEM_PROPERTIES[itemKey].price;
    if (typeof cost === 'undefined') {
      console.error(`item price not defined for ${itemKey}`); return 'error';
    }
    const reductionAmount = count * cost;
    const modCoinsF = this.inventory.modGoldT(-reductionAmount);
    if (modCoinsF === 'error') return 'error';
    const isError = this.inventory.mod(itemKey, count);
    if (!isError) {
      modCoinsF();
      return 'success';
    } return 'error';
  };

  versus = (pointChoice: PointVarNames, enemyPoints: string): 'win' | 'loss' => {
    const playerPointsGameVars = fromPairs(Object.values(PointVarNames)
      .map((varName) => [varName, this.getGameVariable(varName)]));
    return playerPointsGameVars[pointChoice] > this.getGameVariable(enemyPoints) ? 'win' : 'loss';
  };

  pointsNeeded = (pointChoice: PointVarNames, pointsRequired: number) => {
    const playerPointsGameVars = fromPairs(Object.values(PointVarNames)
      .map((varName) => [varName, this.getGameVariable(varName)]));
    const pointNames: {[k in PointVarNames]: string} = {
      [SKILL_POINTS]: 'Skill',
      [SONG_POINTS]: 'Song',
      // [SOCIAL_POINTS]: 'Social',
    };
    return `You need ${pointsRequired - playerPointsGameVars[pointChoice]} more ${pointNames[pointChoice]} points.`;
  };

  showLocationImage = (key: string) => {
    if (this.locationImage.frame.name !== `${key}.png`) {
      this.locationImage.setAlpha(0);
      this.locationImage.setTexture('assets', `${key}.png`);
      this.tweens.add({
        targets: this.locationImage,
        alpha: 1,
        ease: 'Power1',
        duration: 300,
      });
    }
  };

  createExternals() {
    bindExternalFunction(this.story, 'get_FQ_site', () => 'fiddlequest.com', 0, true);
    bindExternalFunction(this.story, 'item_price', itemPrice, 1, true);
    bindExternalFunction(this.story, 'item_description', itemDescription, 1, true);
    bindExternalFunction(this.story, 'item_name', itemName, 1, true);
    bindExternalFunction(this.story, 'buy', this.buy, 2);
    bindExternalFunction(this.story, 'versus', this.versus, 2, true);
    bindExternalFunction(this.story, 'show_location_image', this.showLocationImage, 1, true);
    bindExternalFunction(this.story, 'get_article', getArticle, 1, true);
    bindExternalFunction(this.story, 'inventory_add', this.inventory.mod, 2, false);
    bindExternalFunction(this.story, 'inventory_rm', this.inventory.remove, 1, false);
    bindExternalFunction(this.story, 'add_gold', ary1(void1(this.inventory.modGold)), 1, false);
    bindExternalFunction(this.story, 'load', this.loadGame, 0, false);
    bindExternalFunction(this.story, 'save', this.saveGame, 0, false);
    bindExternalFunction(this.story, 'points_needed', this.pointsNeeded, 2, true);
    bindExternalFunction(this.story, 'is_location_visited', isLocationVisited, 1, true);
    bindExternalBadgesFunctions(this.story);
  }

  setPointVarValue = (k: PointVarNames, v: number) => this.setGameVariable(k, v);

  setGameVariable = (txt: string, newValue: any): void => {
    // @ts-ignore
    this.story.variablesState.$(txt, newValue);
    this.rerenderVariable(txt);
  };

  rerenderVariable = (variableName: string) => {
    if (Object.values(PointVarNames).map((t) => t.toString()).indexOf(variableName) !== -1) {
      this.updatePointTexts();
    }
  };

  // @ts-ignore
  getGameVariable = (txt: string) => this.story.variablesState[txt];

  watchGameVariable = (k: string, cb: (v: InkObject) => void): () => void => {
    const inkCb = (name: string, value: InkObject) => {
      if (k !== name) return;
      cb(value);
    };
    this.story.variablesState.ObserveVariableChange(inkCb);
    return (): void => {
      this.story.variablesState.variableChangedEventCallbacks = (
        this.story.variablesState.variableChangedEventCallbacks.filter((inkCb_) => inkCb_ !== inkCb));
    };
  };

  getGameVariables = (): VariablesState => this.story.variablesState;

  updatePointTexts = () => {
    this.textSongPoints.setText(`Song:${this.getGameVariable(SONG_POINTS)}`);
    this.textSkillPoints.setText(`Skill:${this.getGameVariable(SKILL_POINTS)}`);
    // this.textSocialPoints.setText(`Social:${this.getGameVariable(SOCIAL_POINTS)}`);
  };

  continueStory = (jump?: string): void => {
    let text = '';

    if (typeof jump !== 'undefined') {
      this.story.ChoosePathString(jump);
      this.choicesGroup.clear(true, true);
    }

    while (this.story.canContinue) {
      const paragraphText = this.story.Continue();

      text += `${paragraphText}\n`;
      this.textCoins.text = this.getGameVariable('coins');
      this.updatePointTexts();
      if (!this.story.canContinue) {
        const storyFragment = {
          choices: this.story.currentChoices,
          tags: this.story.currentTags,
          text: text.trim(),
        };
        this.createParagraphText(storyFragment);
      }
    }
  };

  createParagraphText = ({ text, choices }: { text: string, choices: any[] }) => {
    this.textParagraph.text = text;
    this.addChoices(choices);
  };

  addChoices = (choices: any[]) => {
    const choiceTextStyle = {
      fontSize: FONT_SIZE_BUTTON,
      fontFamily: 'Roboto Mono',
      fill: '#5f9eee',
      wordWrapWidth,
      align: 'center',
    };
    // determines how far up to start list of choices
    let listBottom = 0;
    const inShop = this.getGameVariable(IN_SHOP_VAR_NAME);
    if (inShop) {
      listBottom = 1198;
    } else {
      listBottom = 1430;
    }
    choices.forEach((choice: any) => {
      const bgChoice = this.add.image(0, 0, 'assets', 'bg_choice.png');
      const choiceText = this.add
        .text(0, 0, choice.text, choiceTextStyle)
        .setOrigin(0.5);
      const choiceContainer = this.add
        // .container(1400, 2000, [bgChoice, choiceText])
        .container(1396, 900, [bgChoice, choiceText])
        .setAlpha(0)
        .setSize(bgChoice.width, bgChoice.height)
        .setInteractive();
      this.tweens.add({
        targets: choiceContainer,
        y: listBottom - choice.index * bgChoice.height * 1.32,
        alpha: 1,
        ease: 'Power1',
        duration: 600,
      });
      choiceContainer.on('pointerover', () => {
        choiceContainer.list[1].setFill('#ffffff');
      });
      choiceContainer.on('pointerout', () => {
        choiceContainer.list[1].setFill('#5f9eee');
      });
      choiceContainer.on('pointerdown', () => {
        this.story.ChooseChoiceIndex(choice.index);
        this.choicesGroup.clear(true, true);
        this.continueStory();
      });
      this.choicesGroup.add(choiceContainer);
    });
  };

  createShopButton = () => {
    const shopButtonStyle = {
      fontSize: FONT_SIZE_BUTTON,
      fontFamily: 'Roboto Mono',
      fill: '#5f9eee',
      wordWrapWidth,
      align: 'center',
    };
    const shopButton = this.add.image(0, 0, 'assets', 'button.png');
    const shopText = this.add
      .text(0, 0, 'Shop', shopButtonStyle)
      .setOrigin(0.5);
    const shopButtonContainer = this.add
      .container(394, 0, [shopButton, shopText])
      .setAlpha(0)
      .setSize(shopButton.width, shopButton.height)
      .setInteractive();
    this.tweens.add({
      targets: shopButtonContainer,
      y: 1477,
      alpha: 1,
      ease: 'Power1',
      duration: 0,
    });
    shopButtonContainer.on('pointerover', (): void => {
      shopButtonContainer.list[1].setFill('#ffffff');
    });
    shopButtonContainer.on('pointerout', (): void => {
      shopButtonContainer.list[1].setFill('#5f9eee');
    });
    shopButtonContainer.on('pointerdown', (): void => {
      this.continueStory('Shop');
    });
  };

  createGUI = (): void => {
    this.choicesGroup = this.add.group();
    this.add.image(0, 0, 'assets', 'fq_main.png').setOrigin(0, 0);
    this.createShopButton();
    const paragraphStyle = {
      fontSize: 35,
      fontFamily: 'Roboto Mono',
      fill: '#ffffff',
      wordWrap: { width: wordWrapWidth },
      align: 'left',
    };

    const labelCoinStyle = {
      fontSize: 30,
      fontFamily: 'Roboto Mono',
      fill: '#fff',
      wordWrap: { width: wordWrapWidth },
      align: 'center',
    };

    this.textParagraph = this.add.text(...zoneCoords.textParagraph, '', paragraphStyle);
    this.textCoins = this.add.text(...zoneCoords.coinText, '', labelCoinStyle);
    this.textSongPoints = this.add.text(...zoneCoords.pointUiSongPointsText, '', labelPointStyle);
    this.textSkillPoints = this.add.text(...zoneCoords.pointUiSkillPointsText, '', labelPointStyle);
    // this.textSocialPoints = this.add.text(...zoneCoords.pointUiSocialPointsText, '', labelPointStyle);
    const [textCreditsButton, destroyTextCreditsButton] = initCreditsButton(this);
    this.textCredits = textCreditsButton;
    this.destroyTextCredits = destroyTextCreditsButton;
    this.locationImage = this.add.image(...zoneCoords.locationImage, '').setAlpha(0);
    this.itemImage = this.add.image(...zoneCoords.itemImage, '').setAlpha(0);
  };

  destroyGUI = (): void => {
    if (this.destroyTextCredits) this.destroyTextCredits();
    delete this.textCredits;
  };
}
