export enum PointVarNames {
  SONG_POINTS = 'song_points',
  SKILL_POINTS = 'skill_points',
  // SOCIAL_POINTS = 'social_points',
}

export enum PointTypes {
  SONG = 'song',
  SKILL = 'skill',
  SOCIAL = 'social',
}

export const toVarName = (type: PointTypes): PointVarNames => {
  const name = Object.values(PointVarNames).find((v) => v === `${type}_points`);
  if (!name) throw new Error(`no points var name for type: ${type}`);
  return name;
};
