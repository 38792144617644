import React, { useContext, useMemo } from 'react';
import { AssetsProvider } from './provider';
import { AssetName } from '../../../../assets/constants';

export const assetsContext = React.createContext<AssetsProvider | undefined>(undefined);

export const useAssetsContext = (): AssetsProvider => {
  const assets = useContext(assetsContext);
  if (!assets) {
    throw new Error('no assets context');
  }
  return assets;
};

export const useBase64png = (a: AssetName): string => {
  const assets = useAssetsContext();
  return useMemo(() => assets.getBase64png(a), [assets]);
};
