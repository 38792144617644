import { PhaserContainer } from './container';

export class ObjectsContainer<T extends Phaser.GameObjects.GameObject> extends PhaserContainer {
  add = (o: T): this => {
    this.container.add(o);
    return this;
  };

  remove = (o: T): this => {
    this.container.remove(o, true);
    return this;
  };

  getChildren = () => this.container.getAll() as T[];
}
