type Impossible<K extends keyof any> = {
  [P in K]: never;
};

export type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;

export const ary0 = <R>(f: (...xs: any[]) => R): (() => R) => () => f();
export const ary1 = <T, R>(f: (x1: T, ...xs: any[]) => R): ((x: T) => R) => (x: T) => f(x);
export const ary2 = <T, T2, R>(f: (x1: T, x2: T2, ...xs: any[]) => R): ((x1: T, x2: T2) => R) =>
  (x1: T, x2: T2) => f(x1, x2);

export const void0 = <R>(f: (...xs: any[]) => R): (() => void) => () => { f(); };
export const void1 = <T, R>(f: (x1: T, ...xs: any[]) => R): ((x: T) => void) => (x: T) => { f(x); };
export const void2 = <T, T2, R>(f: (x1: T, x2: T2, ...xs: any[]) => R): ((x1: T, x2: T2) => void) =>
  (x1: T, x2: T2) => { f(x1, x2); };

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
