import { useEffect, useState } from 'react';
import { useScaleManagerContext } from './context';
import { ScaleWrapperResult } from './types';

type Transform = (c: number) => number;

export function useWrapperScale(): ScaleWrapperResult {
  const sm = useScaleManagerContext();
  const getValue = (): ScaleWrapperResult => {
    const { x: scaleX, y: scaleY } = sm.displayScale;
    const { width, height } = sm.canvasBounds;
    const transformX: Transform = (x0) => x0 / scaleX;
    const transformY: Transform = (y0) => y0 / scaleY;
    return {
      width,
      height,
      transformX,
      transformY,
      scaleX: 1 / scaleX, // their way doesn't look like conventional (?), conventionalise it back
      scaleY: 1 / scaleY,
    };
  };
  const [wrapperSize, setWrapperSize] = useState<ScaleWrapperResult>(getValue());
  useEffect(() => {
    if (!sm) return (): void => {};
    const handleResize = (): void => { setWrapperSize(getValue()); };
    sm.on(Phaser.Scale.Events.RESIZE, handleResize);
    // Handler to call on window resize
    handleResize();
    return (): void => { sm.off(Phaser.Scale.Events.RESIZE, handleResize); };
  }, [sm]);
  return wrapperSize;
}
