import io from 'socket.io-client';
import assert from 'assert';
import { path as socketPath } from '../constants';

let socket: SocketIOClient.Socket | null = null;

export const init = () => {
  if (socket) return;
  socket = io({
    path: socketPath,
  });
};

export const destroy = () => {
  if (socket) {
    socket.close();
    socket = null;
  }
};

export const getSocket = () => {
  if (!socket) init();
  assert(socket);
  return socket;
};
