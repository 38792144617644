{
	"textures": [
		{
			"image": "assets.png",
			"format": "RGBA8888",
			"size": {
				"w": 4093,
				"h": 4181
			},
			"scale": 1,
			"frames": [
				{
					"filename": "fq_main.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 2048,
						"h": 1536
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 2048,
						"h": 1536
					},
					"frame": {
						"x": 1,
						"y": 1,
						"w": 2048,
						"h": 1536
					}
				},
				{
					"filename": "fq f.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 706,
						"h": 644
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 706,
						"h": 644
					},
					"frame": {
						"x": 1,
						"y": 1539,
						"w": 706,
						"h": 644
					}
				},
				{
					"filename": "Alcove.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2051,
						"y": 1,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "BeginYourQuest.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1,
						"y": 2185,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "Intro.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 709,
						"y": 1539,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "bandit_camp.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2051,
						"y": 612,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "bandit_camp_chasm.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2728,
						"y": 1,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "buy_before_battle.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1,
						"y": 2796,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "cauldron_room.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2728,
						"y": 612,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "cave_dark.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 3405,
						"y": 1,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "cave_room_1.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1,
						"y": 3407,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "cave_room_1_flee.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 3405,
						"y": 612,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "cramped_tunnel.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1386,
						"y": 1539,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "bg_choice.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 1216,
						"h": 64
					},
					"spriteSourceSize": {
						"x": 102,
						"y": 0,
						"w": 1012,
						"h": 64
					},
					"frame": {
						"x": 2051,
						"y": 1223,
						"w": 1012,
						"h": 64
					}
				},
				{
					"filename": "dream.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2063,
						"y": 1289,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "fled_witch_campsite.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2740,
						"y": 1289,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "flee_cave_flaming_tree.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 3417,
						"y": 1223,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "grassy_clearing.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2063,
						"y": 1900,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "grimbald_guides.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2740,
						"y": 1900,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "magic_defense_music.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 3417,
						"y": 1834,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "magic_drum_scene.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 3417,
						"y": 2445,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "button.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 674,
						"h": 64
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 674,
						"h": 64
					},
					"frame": {
						"x": 1,
						"y": 4018,
						"w": 674,
						"h": 64
					}
				},
				{
					"filename": "path_from_river_woods.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 709,
						"y": 2150,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "pathways_dungeon.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1386,
						"y": 2150,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "river.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 678,
						"y": 2761,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "riverbed_city.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 678,
						"y": 3372,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "shop.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1355,
						"y": 2761,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "siege_beginning.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 1355,
						"y": 3372,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "spell_training_grounds.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2063,
						"y": 2511,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "sword_training_grounds.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 675,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 675,
						"h": 609
					},
					"frame": {
						"x": 2740,
						"y": 2511,
						"w": 675,
						"h": 609
					}
				},
				{
					"filename": "badge_magic_instrument_collector.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 609,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 1,
						"w": 607,
						"h": 607
					},
					"frame": {
						"x": 2032,
						"y": 3122,
						"w": 607,
						"h": 607
					}
				},
				{
					"filename": "badge_trained_in_grimroot.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 609,
						"h": 609
					},
					"spriteSourceSize": {
						"x": 1,
						"y": 1,
						"w": 607,
						"h": 607
					},
					"frame": {
						"x": 2641,
						"y": 3122,
						"w": 607,
						"h": 607
					}
				},
				{
					"filename": "coins.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 480,
						"h": 480
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 480,
						"h": 449
					},
					"frame": {
						"x": 2032,
						"y": 3731,
						"w": 480,
						"h": 449
					}
				},
				{
					"filename": "dummy_bucket.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 546,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 546,
						"h": 160
					},
					"frame": {
						"x": 2514,
						"y": 3731,
						"w": 546,
						"h": 160
					}
				},
				{
					"filename": "items_bucket.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 546,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 546,
						"h": 160
					},
					"frame": {
						"x": 2514,
						"y": 3893,
						"w": 546,
						"h": 160
					}
				},
				{
					"filename": "badge_escaped_bandits.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 512,
						"h": 512
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 1,
						"w": 512,
						"h": 510
					},
					"frame": {
						"x": 3250,
						"y": 3122,
						"w": 512,
						"h": 510
					}
				},
				{
					"filename": "badge_escaped_water_spirit.png",
					"rotated": false,
					"trimmed": true,
					"sourceSize": {
						"w": 512,
						"h": 512
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 1,
						"w": 512,
						"h": 510
					},
					"frame": {
						"x": 3250,
						"y": 3634,
						"w": 512,
						"h": 510
					}
				},
				{
					"filename": "runes_bucket.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 546,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 546,
						"h": 160
					},
					"frame": {
						"x": 678,
						"y": 3983,
						"w": 546,
						"h": 160
					}
				},
				{
					"filename": "weapons_bucket.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 546,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 546,
						"h": 160
					},
					"frame": {
						"x": 1226,
						"y": 3983,
						"w": 546,
						"h": 160
					}
				},
				{
					"filename": "dummy_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3062,
						"y": 3731,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "dwarven_dagger_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 1774,
						"y": 3983,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "health_potion_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3062,
						"y": 3893,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "lantern_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3764,
						"y": 3056,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "magic_drum_broken_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3764,
						"y": 3218,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "magic_drum_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3926,
						"y": 3056,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "magic_fiddle_bow_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3764,
						"y": 3380,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "rune_of_fire_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3926,
						"y": 3218,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "rune_of_persuasion_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3764,
						"y": 3542,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "rune_of_strength_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3926,
						"y": 3380,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "smoke_bomb_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3764,
						"y": 3704,
						"w": 160,
						"h": 160
					}
				},
				{
					"filename": "torch_thumb.png",
					"rotated": false,
					"trimmed": false,
					"sourceSize": {
						"w": 160,
						"h": 160
					},
					"spriteSourceSize": {
						"x": 0,
						"y": 0,
						"w": 160,
						"h": 160
					},
					"frame": {
						"x": 3926,
						"y": 3542,
						"w": 160,
						"h": 160
					}
				}
			]
		}
	],
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "3.0",
		"smartupdate": "$TexturePacker:SmartUpdate:0d21440f6a6897f14a38755bfab163a5:1ad95fa17194d823f1ed9162c9474de1:a759b6bbc8381ac9c472b28f589ad0d6$"
	}
}
