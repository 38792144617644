import { InventoryItemKeysType } from './keys';
import { INVENTORY_ITEM_PROPERTIES, InventoryItemProperties } from './properties';
import { InventoryItemKind } from './kinds';

const prop = <K extends keyof InventoryItemProperties>(k: K):
((itemKey: InventoryItemKeysType) => InventoryItemProperties[K]) => (itemKey) =>
    INVENTORY_ITEM_PROPERTIES[itemKey][k];

export const itemPrice: (k: InventoryItemKeysType) => number | undefined = prop('price');
export const itemKind: (k: InventoryItemKeysType) => InventoryItemKind = prop('kind');
export const itemIsStackable: (k: InventoryItemKeysType) => boolean = prop('stackable');
export const itemDescription: (k: InventoryItemKeysType) => string | undefined = prop('description');
export const itemName: (k: InventoryItemKeysType) => string | undefined = prop('name');
export const itemKindIs = (k: InventoryItemKeysType, kind: InventoryItemKind | null): boolean =>
  kind === itemKind(k);
