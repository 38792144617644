import React from 'react';
import { observer } from 'mobx-react-lite';
import { useWrapperScale } from '../../../htmlOverlay/client/scale/hooks';
import { badgesClientState } from '../state';
import { BadgeList } from './badgeList';
import { BadgesDimensions } from '../dimensions';
import { BadgesButton } from './badgesButton';
import { Modal } from '../../../modal';
import { action } from 'mobx';

const AdjustedBadgesContainer: React.FC = ({ children }) => {
  const { transformX, transformY, scaleX, scaleY } = useWrapperScale();
  const { buttonX: x0, buttonY: y0 } = BadgesDimensions;
  const [x, y] = [transformX(x0), transformY(y0)];
  return <div style={{
    position: 'absolute', left: x, top: y,
    transform: `scale(${scaleX}, ${scaleY})`,
    transformOrigin: 'left top',
  }}>{children}</div>;
};

const closeBadgesList = action((): void => {
  badgesClientState.isBadgeListOpen = false;
});

export const BadgesOverlay: React.FC = observer(() => <AdjustedBadgesContainer>
    <BadgesButton />
    <Modal bodyContent={<BadgeList />} show={badgesClientState.isBadgeListOpen} onHide={closeBadgesList} />
  </AdjustedBadgesContainer>);