import { InkObject } from 'inkjs/engine/Object';
import { Value } from 'inkjs/engine/Value';
import { WithStory } from '../../story/types';
import { markLocationVisited } from './store';
import { isLoading } from '../../saveLoad/client';

export const watchVisitedLocation = (s: WithStory, cb: (l: string) => void): () => void =>
  s.watchGameVariable('current_location', (l: InkObject) => {
    if (isLoading()) return;
    const v = (l as Value<string>).value?.toString();
    if (v !== null && typeof v !== 'undefined') cb(v);
  });

export const initVisitedLocationsInkWatcher = (s: WithStory): () => void =>
  watchVisitedLocation(s, markLocationVisited);
