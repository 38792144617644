import { AssetName } from '../../../../assets/constants';

export interface AssetsProvider {
  getBase64png(k: AssetName): string;
  getFrame(k: AssetName): Frame;
}
export interface Frame extends Pick<Phaser.Textures.Frame, 'x' | 'y' | 'width' | 'height'> {

}
const toFrame = (f: Phaser.Textures.Frame): Frame => ({
  x: f.x,
  y: f.y,
  width: f.width,
  height: f.height,
});
const toKey = (k: AssetName): string => (k.endsWith('.png') ? k : `${k}.png`);
export const makeAssetsProvider = (tm: Phaser.Textures.TextureManager): AssetsProvider => ({
  getBase64png: (k) => tm.getBase64('assets', toKey(k)),
  getFrame: (k) => toFrame(tm.getFrame('assets', toKey(k))),
});
