import { labelPointStyle } from '../../../scenes/styles';

export const creditsButtonCoords: [number, number] = [1905, 10];

const creditsUrl = process.env.FIDDLEQUEST_GAME_CREDITS_URL;
if (!creditsUrl) throw new Error('FIDDLEQUEST_GAME_CREDITS_URL not set');

const openCreditsTab = (): void => {
  window.open(creditsUrl, '_blank');
};

export const initCreditsButton = (scene: Phaser.Scene): [Phaser.GameObjects.Text, () => void] => {
  const b = scene.add.text(...creditsButtonCoords, 'Credits', labelPointStyle).setInteractive();
  const e = 'pointerdown';
  b.on(e, openCreditsTab);
  return [b, (): void => {
    b.off(e, openCreditsTab);
  }];
};
