export class PhaserContainer {
  protected readonly container: Phaser.GameObjects.Container;

  protected readonly depth: number;

  protected readonly hiddenDepth: number = -1;

  constructor(scene: Phaser.Scene, parent?: PhaserContainer, x?: number, y?: number) {
    this.container = scene.add.container(x, y);
    parent?.container.add(this.container);
    this.depth = this.container.depth;
  }

  clear = <Args extends PhaserContainerClearArgs[]>(...args: Args): this => {
    const removeFromScene = args && args.includes('removeFromScene');
    this.container.destroy(removeFromScene);
    return this;
  };

  toggleDisplay = (b: boolean): this => {
    this.container.setVisible(b);
    this.container.setDepth(b ? this.depth : this.hiddenDepth);
    return this;
  };
}

export type PhaserContainerClearArgs = 'removeFromScene';
