import { Story as InkStory } from 'inkjs/engine/Story';
import { badgesExternalFunctionNames } from '../badges/client/game/functionNames';
import { locationsExternalFunctionNames } from '../visitedLocations/client/gameFunctionNames';

const inkExternalFunctionNames = [
  'get_FQ_site', 'item_price', 'item_name', 'item_description', 'buy', 'versus', 'show_location_image', 'get_article',
  'inventory_add', 'inventory_rm',
  'load', 'save', 'points_needed', 'add_gold', ...badgesExternalFunctionNames, ...locationsExternalFunctionNames,
] as const;
export type InkExternalFunctionName = typeof inkExternalFunctionNames[number];

// ink is very sensitive to number of function params.
// for instance if you had f(a) and changed it to f(a, b = true) it would fail
// we mitigate it with this lib

export const bindExternalFunction = (story: InkStory,
  functionName: InkExternalFunctionName,
  f: (...args: any[]) => any,
  arity: number = 0, lookaheadSafe = false) : void => {
  if (f.length !== arity) throw new Error(`External function ${functionName} expected ${arity} got ${f.length}`);
  story.BindExternalFunction(functionName, f, lookaheadSafe);
};
