import { makeAutoObservable } from 'mobx';
import { InventoryItemKind } from '../../items/kinds';

class Tabs {
  currentTab: null | InventoryItemKind = null;

  constructor() {
    makeAutoObservable(this);
  }
}

export const inventoryTabsClientState = new Tabs();
