export const wordWrapWidth = 970;

type CoordsStore = {
  [k: string]: [number, number];
};
export const zoneCoords: CoordsStore = {
  textParagraph: [930, 230],
  coinText: [158, 786],
  pointUiSongPointsText: [275, 786],
  pointUiSkillPointsText: [490, 786],
  // pointUiSocialPointsText: [540, 786],
  locationImage: [400, 438],
  itemImage: [400, 432],
};
