import { Actions, Scene } from 'phaser';
import { PhaserContainer } from '../../../../phaser/client/objects/container';
import { ObjectsContainer } from '../../../../phaser/client/objects/objects';
import { InventoryItemKeysType, isInventoryItemKey } from '../../../items/keys';
import { INVENTORY_ITEM_PROPERTIES } from '../../../items/properties';
import { InventoryItem } from '../../story';
import { InventoryDimensions } from './coordinates';

const DUMMY_ALPHA = 0.1;
const DUMMY_TEXTURE = 'dummy_thumb.png';
// const DUMMY_TEXTURE = 'dummy_bucket.png';

type Text = Phaser.GameObjects.Text;
type Image = Phaser.GameObjects.Image;

const COUNT_BOX_COUNT_DATA_KEY = 'count';

type ItemRenderProps = { alpha: number, name: string };
type CountBoxRenderProps = { alpha: number, name: string, count: number | undefined };

const setCountBoxProps = (countBox: Text, { alpha, name, count } :
{ alpha: number, name: string, count: number | undefined }): Text =>
  countBox.setAlpha(alpha).setText(typeof count === 'number' ? count.toString() : '')
    .setName(name).setData(COUNT_BOX_COUNT_DATA_KEY, count);

const modItemCountCell = (cell: Text, itemKey: InventoryItemKeysType, value: number): void => {
  const { stackable } = INVENTORY_ITEM_PROPERTIES[itemKey];
  if (stackable && !value) console.error('stackable count cell supposed to have value');
  setCountBoxProps(cell, { name: itemKey, alpha: stackable ? 1 : 0, count: value });
};

const setItemProps = (image: Image, { alpha, name } : ItemRenderProps): Image =>
  image.setTexture('assets', name ? `${name}_thumb.png` : DUMMY_TEXTURE).setAlpha(alpha).setName(name);

const renderItemTileProps = (itemKey: InventoryItemKeysType,
  amount: number | undefined,
  itemSpace: Image,
  countSpace: Text): void => {
  setItemProps(itemSpace, { name: itemKey, alpha: 1 });
  modItemCountCell(countSpace, itemKey, amount || 0);
};

const getItemProps = (image: Image): ItemRenderProps => ({ alpha: image.alpha, name: image.name });

const getCountBoxProps = (countBox: Text): CountBoxRenderProps =>
  ({ alpha: countBox.alpha, name: countBox.name, count: countBox.getData(COUNT_BOX_COUNT_DATA_KEY) });

const setDummyItemProps = (i: Image): Image => setItemProps(i, { alpha: DUMMY_ALPHA, name: '' });
const setDummyCountBoxProps = (t: Text) : Text => setCountBoxProps(t, { alpha: 0, name: '', count: undefined });

export class InventoryGrid {
  private readonly scene: Scene;

  constructor(scene: Scene) {
    this.scene = scene;
  }

  private container!: ObjectsContainer<Phaser.GameObjects.GameObject>;

  private inventoryTilesContainer!: ObjectsContainer<Phaser.GameObjects.Image>;

  private numberContainer!: ObjectsContainer<Phaser.GameObjects.Text>;

  private nextEmptySlotIndex!: number;

  private align = (): void => {
    Actions.GridAlign(this.inventoryTilesContainer.getChildren(), {
      width: InventoryDimensions.horizontalCellCount,
      // width: 1,
      height: InventoryDimensions.verticalCellCount,
      cellWidth: InventoryDimensions.cellSide,
      cellHeight: InventoryDimensions.cellSide,
      x: 0,
      y: 0,
    });
    Actions.GridAlign(this.numberContainer.getChildren(), {
      width: InventoryDimensions.horizontalCellCount,
      height: InventoryDimensions.verticalCellCount,
      cellWidth: InventoryDimensions.cellSide,
      cellHeight: InventoryDimensions.cellSide,
      x: 20,
      y: 15,
    });
  };

  private clearInventoryTiles = (): void => {
    this.inventoryTilesContainer.getChildren().forEach(setDummyItemProps);
    this.numberContainer.getChildren().forEach(setDummyCountBoxProps);
    this.nextEmptySlotIndex = 0;
  };

  private reconcileInventoryTiles = (): void => {
    const shallWe = this.inventoryTilesContainer.getChildren()
      .map((t) => t.name).reduce(({ foundEmpty, foundFullAfterEmpty }, name) => ({
        foundEmpty: foundEmpty || !name,
        foundFullAfterEmpty: foundFullAfterEmpty || (foundEmpty && !!name),
      }), { foundEmpty: false, foundFullAfterEmpty: false }).foundFullAfterEmpty;
    if (!shallWe) return;
    const noHolesItemsProps = this.inventoryTilesContainer.getChildren().filter((t) => !!t.name).map(getItemProps);
    const noHolesCountsProps = this.numberContainer.getChildren().filter((t) => !!t.name).map(getCountBoxProps);
    this.clearInventoryTiles();
    noHolesItemsProps.forEach((p, index) => setItemProps(this.inventoryTilesContainer.getChildren()[index], p));
    noHolesCountsProps.forEach((p, index) => setCountBoxProps(this.numberContainer.getChildren()[index], p));
  };

  init = (onItemClick: (k: InventoryItemKeysType) => void, parent: PhaserContainer) => {
    this.nextEmptySlotIndex = 0;
    this.container?.clear('removeFromScene');
    this.container = this.container || new ObjectsContainer(this.scene, parent);
    this.inventoryTilesContainer = this.inventoryTilesContainer || new ObjectsContainer(this.scene, this.container);
    this.numberContainer = this.numberContainer || new ObjectsContainer(this.scene, this.container);
    const INVENTORY_GRID_SIZE = 9;
    // const INVENTORY_GRID_SIZE = 3;
    const destroys = Array(INVENTORY_GRID_SIZE)
      .fill(null)
      .map(() => {
        // make image with dummy_thumb
        const dummy = setDummyItemProps(this.scene.add.image(0, 0, DUMMY_TEXTURE)).setInteractive();
        const dummyNumber = setDummyCountBoxProps(
          this.scene.add.text(0, 0, 'count', { fontFamily: 'Roboto Mono', fontSize: 30 }),
        );
        const event = 'pointerdown'; const
          listener = () => {
            if (!dummy.name) {
              return;
            }
            if (!isInventoryItemKey(dummy.name)) {
              console.error(`item ${dummy.name} is not an inventory item`);
              return;
            }
            onItemClick(dummy.name);
          };
        dummy.on(event, listener);
        /* dummy.on("pointerover", (pointer) => {
          this.textItemLabel.text = dummy.name;
        }); */
        this.inventoryTilesContainer.add(dummy);
        this.numberContainer.add(dummyNumber);
        return (): void => {
          dummy.off(event, listener);
          this.inventoryTilesContainer.remove(dummy);
          this.numberContainer.remove(dummyNumber);
        };
      });
    this.align();
    return (): void => {
      destroys.forEach((d) => d());
    };
  };

  private nextItemSpace = (): Image =>
    this.inventoryTilesContainer.getChildren()[this.nextEmptySlotIndex];

  private nextCountBoxSpace = (): Text =>
    this.numberContainer.getChildren()[this.nextEmptySlotIndex];

  private findSceneItem = (itemKey: InventoryItemKeysType): Image | undefined =>
    this.inventoryTilesContainer.getChildren().find((i: Image) => i.name === itemKey);

  private findCountBox = (itemKey: InventoryItemKeysType): Text | undefined =>
    this.numberContainer.getChildren().find((i: Text) => i.name === itemKey);

  removeItem = (itemKey: InventoryItemKeysType): void => {
    const sceneItem: Image | undefined = this.findSceneItem(itemKey);
    const countBox: Text | undefined = this.findCountBox(itemKey);
    if (countBox && sceneItem) {
      this._removeFromScene(sceneItem, countBox);
    } else if (/* xor */countBox ? !sceneItem : sceneItem) {
      console.error('Illegal state: sceneItem xor countBox = true');
    }
  };

  private _removeFromScene = (sceneItem: Image, countBox: Text): void => {
    setDummyItemProps(sceneItem);
    setDummyCountBoxProps(countBox);
    this.nextEmptySlotIndex--;
    this.reconcileInventoryTiles();
  };

  renderNewItem = (itemKey: InventoryItemKeysType, amount: number | undefined): void => {
    const nextItemSpace = this.nextItemSpace();
    const nextCountBoxSpace = this.nextCountBoxSpace();
    renderItemTileProps(itemKey, amount, nextItemSpace, nextCountBoxSpace);
    this.nextEmptySlotIndex++;
  };

  rerenderWithItems = (itemsOfKind: InventoryItem[]): void => {
    this.clearInventoryTiles();
    itemsOfKind.forEach((i) => this.renderNewItem(i.meta.key, i.amount));
  };

  mod = (itemKey: InventoryItemKeysType, oldAmount: number, newAmount: number, stackable: boolean): void => {
    if (oldAmount) {
      if (stackable) {
        const foundNumber = this.findCountBox(itemKey);
        if (foundNumber) {
          modItemCountCell(foundNumber, itemKey, newAmount);
        }
      }
    } else {
      this.renderNewItem(itemKey, newAmount);
    }
  };

  toggleDisplay = (b: boolean) => {
    this.container.toggleDisplay(b);
  };
}
