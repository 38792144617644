import React, { MouseEvent } from 'react';
import { Button } from 'react-bootstrap';
import { InventoryDimensions } from '../scenes/inventory/coordinates';
import { useWrapperScale } from '../../../htmlOverlay/client/scale/hooks';
import { action } from 'mobx';
import { inventoryTabsClientState } from '../state';
import { inventoryShift } from './inventoryShift';


export const InventoryBackButton: React.FC = () => {
  const onClick = action((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    inventoryTabsClientState.currentTab = null;
  });
  const scale = useWrapperScale();
  const { transformX, scaleX, scaleY } = scale;
  const shift = inventoryShift(scale);
  return <Button variant="dark" style={{
    position: 'absolute',
    top: shift.top,
    left: `${-transformX(InventoryDimensions.cellSide)}px`,
    height: `${InventoryDimensions.height}px`,
    width: `${InventoryDimensions.cellSide / 2}px`,
    transform: `scale(${scaleX}, ${scaleY})`,
    transformOrigin: 'left top',
    borderRadius: '9999px',
    fontSize: `${InventoryDimensions.cellSide / 2 / 2}px`
  }} onClick={onClick}>{'<'}</Button>;
};