import React, { useCallback } from 'react';
import { Image, ImageProps } from 'react-bootstrap';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import omit from 'lodash/omit';

import { badgeIds } from '../../constants';
import { BadgeId } from '../../types';
import { useBase64png } from '../../../htmlOverlay/client/assets/context';
import { badgeAssets } from '../assets/badges';
import { toggleBadge, useBadgesQuery } from '../state';

const StyledBadgeImage = styled(Image)`
  height: 15rem;
  width: 15rem;
`;
interface BadgeNoTransmitProps { badgeId: BadgeId; active: boolean; }
const badgeOmitPropsTypeCheck: {[k in keyof BadgeNoTransmitProps]: any} = {
  badgeId: true,
  active: true,
};
const badgeOmitProps = Object.keys(badgeOmitPropsTypeCheck);
interface BadgeProps extends BadgeNoTransmitProps, ImageProps { style?: {[k: string]: string} }
const Badge: React.FC<BadgeProps> = (props) => {
  const base64png = useBase64png(badgeAssets[props.badgeId]);
  return <StyledBadgeImage {...omit(props, badgeOmitProps)} src={base64png} fluid />;
};

const MaybeBadge: React.FC<BadgeProps> = observer((props) => {
  const toggle = useCallback((e: any) => {
    e.preventDefault();
    if (process.env.NODE_ENV !== 'production') {
      toggleBadge(props.badgeId, !props.active).then(() => console.info('[develop] badge toggled')).catch(e => console.error(e));
    }
  }, [props.badgeId, props.active]);
  return <Badge {...props} onClick={toggle} style={{
    ...(props.style || {}),
    opacity: props.active ? '1' : '0.5'
  }} />;
});

export const BadgeList: React.FC = () => {
  const { isLoading, error, data: badges } = useBadgesQuery();
  if (isLoading) return <p>loading</p>;
  if (error) {
    console.error(error);
    return <p>error</p>;
  }
  return <>{
    badgeIds.map((b: BadgeId) => <MaybeBadge key={b} badgeId={b} active={badges!.has(b)} />)
  }</>;
};