import React from 'react';
import { observer } from 'mobx-react-lite';
import { inventoryTabsClientState } from '../state';
import { useWrapperScale } from '../../../htmlOverlay/client/scale/hooks';
import { InventoryDimensions } from '../scenes/inventory/coordinates';
import { InventoryBackButton } from './backButton';
import { InventoryTabButtons } from './tabButtons';

const AdjustedInventoryContainer: React.FC = ({ children }) => {
  const { transformX, transformY, scaleX, scaleY } = useWrapperScale();
  const { x: x0, y: y0 } = InventoryDimensions;
  const { x, y } = { x: transformX(x0), y: transformY(y0) };
  return <div style={{
    position: 'absolute', left: x, top: y,
    // transform: `scale(${scaleX}, ${scaleY})`,
    // transformOrigin: 'left top',
  }}>{children}</div>;
};

export const InventoryOverlay: React.FC = observer(() => <AdjustedInventoryContainer>
  {inventoryTabsClientState.currentTab ? <InventoryBackButton /> : <InventoryTabButtons />}
</AdjustedInventoryContainer>);