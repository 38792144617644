import { GetGameVariables } from './types';

const doNothing = (): void => {};

let autoSaveInitialised = false;
export const initAutoSave = (getGameVariables: GetGameVariables, save: () => void): () => void => {
  if (process.env.DISABLE_AUTOSAVE === 'true') return doNothing;
  if (autoSaveInitialised) throw new Error('auto save already initialised');
  autoSaveInitialised = true;
  const gameVariables = getGameVariables();
  gameVariables.ObserveVariableChange(save);
  return (): void => {
    /* eslint-disable no-param-reassign, max-len */
    gameVariables.variableChangedEventCallbacks = gameVariables.variableChangedEventCallbacks.filter((cb) => cb !== save);
    autoSaveInitialised = false;
  };
};
