import { Game } from 'phaser';
import config from './config';
import { cleanup as pointsCleanup } from '../points/services/client';
import { init as initSocket, destroy as destroySocket } from '../socket/client';
import { initHtmlOverlay } from '../htmlOverlay/client';

let game: Game | null = null;
let destroyHtmlOverlay: null | (() => void) = null;

function newGame() {
  if (game) return;

  game = new Game(config);
  destroyHtmlOverlay = initHtmlOverlay(game.scale, game.textures);
  initSocket();
}

function destroyGame() {
  if (!game) return;
  game.scene.scenes.forEach((s) => {
    // phaser doesn't seem to give us an appropriate hook?
    if (typeof s.customCleanup === 'function') s.customCleanup();
  });
  game.destroy(false);
  pointsCleanup();
  // game.runDestroy();
  destroySocket();
  game = null;
}

function destroy() {
  if (destroyHtmlOverlay) destroyHtmlOverlay();
  destroyGame();
}

if (module.hot) {
  module.hot.dispose(destroy);
  module.hot.accept(newGame);
}

if (!game) newGame();
