import { wordWrapWidth } from '../constants/measurements';

export const labelPointStyle = {
  fontSize: 30,
  fontFamily: 'Roboto Mono',
  fill: '#fff',
  wordWrap: { width: wordWrapWidth },
  align: 'center',
};

export const FONT_SIZE_BUTTON = 40;
